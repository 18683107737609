export const FastPageTargetNavState = {
    banner: {
        value: 1,
        label: "轮播图",
    },
    gird: {
        value: 2,
        label: "宫格导航",
    },
    tabbar: {
        value: 3,
        label: "底部导航",
    },
    floatBar: {
        value: 4,
        label: "浮动按钮",
    },
};